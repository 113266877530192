<template>
  <div class="verify">
    <b-card>
      <b-card-body class="text-center">
        <p>Twój email został potwierdzony, <br>teraz możesz w pełni cieszyć się platformą ChemMaster!</p>
        <router-link to="/sklep">
          <button class="btn btn-primary"><feather-icon icon="ShoppingCartIcon" /> idź do sklepu</button>
        </router-link>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {BCard,BCardBody} from "bootstrap-vue";

export default {
  name: 'Verify',
  methods: {
  },
  components: {
    BCard,BCardBody,
  },
};
</script>
